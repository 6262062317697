<template>
  <div>

      <div class="row mb-4">
        <div class="col-10">
          <div class="form-outline">
      <input type="text" v-model="input" class="form-control" placeholder="فروع الشركة"
           />
          </div>
        </div>
        <div class="col-1">
          <div class="form-outline">
      <a class="btn btn-dark" @click="addToItems">
        <i class="fa fa-plus" aria-hidden="true"></i>

      </a>
          </div>
        </div>
      </div>
<div class="row mb-3">
  <ul class="list-group"  >
    <li class="list-group-item"  v-for="item in items" >
            <a class="btn" @click="deleteItem(item)">
              <i class="fa fa-trash" aria-hidden="true"></i>
            </a>
      <input type="text" class="form-control w-75 d-inline input-lg" :name="'group'+item['id']" :id="item['id']" v-model="item['name']" @change="updateItem(item)"
             style="border: unset!important" >

    </li>
    <input type="hidden" :name="name+'[]'" v-for="item in items" :value="item['name']"/>
  </ul>
</div>


  </div>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      required: true
    },
    branches: {
      type: Array,
      default() {
        return [];
      }

    },
  },
  data() {
    return {
      input: '',
      items: this.branches,
    }
  },
  methods: {
    addToItems() {
      if (!this.input) {
        return;
      }
      let ret = {};
      ret.name = this.input;

      this.items.push(ret);
      this.input = '';
    },
    updateItem(item) {
      // let objIndex = this.items.findIndex((obj => obj.id == item.id ));

      console.log("Before update: ",)

   //   this.items[objIndex].name = item.name

    },
    deleteItem(item) {

      let objIndex = this.items.findIndex((obj => obj.id == item.id ));
      console.log("Before update: ",  this.items[objIndex])


      this.items.splice(objIndex, 1);

    }
  },
  mounted() {

  }
}
</script>
